import {domReady} from '@roots/sage/client';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faBars, faMagnifyingGlass,faChevronDown, faUpRightFromSquare, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faFacebookSquare, faTwitterSquare, faYoutube, faInstagram, faFacebookF, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
library.add(faBars, faLinkedin, faMagnifyingGlass,faFacebookSquare, faTwitterSquare, faYoutube, faChevronDown, faInstagram, faFacebookF, faLinkedinIn,faUpRightFromSquare, faChevronLeft, faChevronRight);
dom.watch();

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  // application code
   
  jQuery(".image-carousel").slick({
      dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
  });
  
  jQuery( document ).ready(function() {
    let header = jQuery('header');
    let menu_trigger = jQuery('header .menu a');
    let menu_overlay = jQuery('.mobile-overlay');
    let search_trigger = jQuery('header .search a');
    let search_menu = jQuery('.mobile-overlay .header-searchMenu');
    let hamburgerNav = jQuery('.mobile-overlay .hamburgerNav');


    //Outside click
    menu_overlay.click(function(e) {
        if(e.target !== this) return;
        window.setTimeout(function(){
            menu_overlay.removeClass('isActive');
        }, 150);
        menu_trigger.removeClass('isActive');
        search_trigger.removeClass('isActive');
        hamburgerNav.removeClass('isActive');
        header.removeClass('isActive');
        search_menu.removeClass('isActive');
    });

    // Mobile Menu
    menu_trigger.click(function(e){
        e.preventDefault();
        search_menu.removeClass('isActive');
        search_trigger.removeClass('isActive');
        menu_overlay.toggleClass('isActive');
        if(menu_overlay.hasClass('isActive')){
            header.toggleClass('isActive');
            jQuery(this).toggleClass('isActive');
            window.setTimeout(function() {
                hamburgerNav.toggleClass('isActive');
            }, 150);
        } else {
            menu_overlay.removeClass('isActive');
            hamburgerNav.removeClass('isActive');
            header.removeClass('isActive');
            jQuery(this).removeClass('isActive');
        }

    });

    // Mobile Search
    search_trigger.click(function(e){
        e.preventDefault();
        
        hamburgerNav.removeClass('isActive');
        menu_trigger.removeClass('isActive');
        menu_overlay.toggleClass('isActive');
        if(menu_overlay.hasClass('isActive')){
            header.toggleClass('isActive');
            jQuery(this).toggleClass('isActive');
            window.setTimeout(function() {
                search_menu.toggleClass('isActive');
            }, 150);    
        } else {
            menu_overlay.removeClass('isActive');
            search_menu.removeClass('isActive');
            header.removeClass('isActive');
            jQuery(this).removeClass('isActive');
        }
    });
    
  });
  
  jQuery(document).ready(function(){
    jQuery('.social-media .container .wrapper .social a').click(function(){
      jQuery('.social-media .container .wrapper .social a').removeClass("active");
      jQuery(this).addClass("active");
  });
  
  jQuery(".cards-carousel").slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: $(".prev"),
    nextArrow: $(".next"),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
jQuery("select.select_hp").click(function() {
  var open = jQuery(this).data("isopen");
  if(open) {
    var url = jQuery(this).val();
    if(url!="0") {
      window.open(url, '_blank');
    }
  }
  jQuery(this).data("isopen", !open);
});

jQuery ('.menu-items a[href="/properties"] span').on('click', function(e) {
  if(location.pathname == "/properties/") {
    e.preventDefault();
  }
  e.preventDefault();
  jQuery(".menu-items .sub-menu").toggle();
});

jQuery ('.menu-items a[href="/properties"]').on('click', function(e) {
  if(location.pathname == "/properties/") {
    e.preventDefault();
  }
});

jQuery ('.menu-main-menu-container .menu-item a[href="/properties"] span').on('click', function(e) {
  if(location.pathname == "/properties/") {
    e.preventDefault();
  }
  e.preventDefault();
  jQuery(".menu-main-menu-container .menu-item .sub-menu").toggle();
});

jQuery ('.menu-main-menu-container .menu-item a[href="/properties"]').on('click', function(e) {
  if(location.pathname == "/properties/") {
    e.preventDefault();
  }
});

};
jQuery(".menu-items .sub-menu a").attr('target', function() {
  if(this.host == location.host) 
    return '_self'
  else return '_blank'
});

jQuery(".menu-item.menu-item-type-custom.menu-item-object-custom.menu-item-has-children .sub-menu a").attr('target', function() {
  if(this.host == location.host)
    return '_self'
  else return '_blank'
});
jQuery(window).load(function(){
   jQuery(".page-overlay-loader").hide();
})

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
